import { Col, Form, Input, Row, Select, Spin } from 'antd';
import useRollbarNotifier from 'customHooks/useRollbarNotifier';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { GeoApiV2 } from 'api/geo-location-v2';
import { AllText, Card, GoogleMapDisplay, ImageViewer, Tag } from 'components';
import { BUSINESS_TYPES, TAG_COLOR } from 'config/acquisition';
import { CONTENT_API_URL } from 'config/url';
import useMediaQuery from 'customHooks/useMediaQuery';
import { isEmpty } from 'lodash';
import { toTitleCase, trimSlashes } from 'utils/formatter/string';

const { Label, TitleText, LabelWithBackground } = AllText;
const DATE_FORMAT = 'DD MMM YYYY HH:mm';

function SectionDetail({ data = {}, loading, editMode, getFieldDecorator, setFieldsValue }) {
  const merchantData = JSON.parse(data?.merchant_data || 'null');
  const additionalData = JSON.parse(data?.additional_data || '{}');
  const isInReview = data.status === 'inreview' || data.status === 'in review';
  const [isLoading, setIsLoading] = useState(false);
  const [villages, setVillages] = useState([]);
  const sendError = useRollbarNotifier();

  const onSearchVillage = async (search) => {
    if (search.length > 2) {
      try {
        setIsLoading(true);
        const res = await GeoApiV2.searchVillage(search);
        setVillages(res?.data?.data || []);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        sendError(`Error when search village on edit acquisition detail page`, error);
      }
    }
  };

  const onChangeLocation = (locationId) => {
    setFieldsValue({
      location_id: locationId,
      location: villages.find((item) => item.id === locationId)?.name,
    });
  };

  // responsive layout
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Card loading={loading} className="card" bordered={false}>
      <Row gutter={16}>
        <Col span={isMobile ? 24 : 12}>
          <Label>User ID</Label>
          <TitleText>{data?.ref_id}</TitleText>

          <Label>Status</Label>
          <Tag color={TAG_COLOR[data?.status]} className="mt-2 pt-0.5">
            {toTitleCase(data?.status || '-')}
          </Tag>

          <Label>Nama Toko</Label>
          {editMode ? (
            <Form.Item>
              {getFieldDecorator('business_name', {
                rules: [{ required: true, message: 'Please input Business Name' }],
                initialValue: data?.business_name || null,
              })(<Input placeholder="Business Name" />)}
            </Form.Item>
          ) : (
            <TitleText>{data?.business_name}</TitleText>
          )}

          <Label>Nama Pemilik Toko</Label>
          {editMode ? (
            <Form.Item>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Please input Nama Pemilik Toko' }],
                initialValue: data?.name || null,
              })(<Input placeholder="Nama Pemilik Toko" />)}
            </Form.Item>
          ) : (
            <TitleText>{data?.name}</TitleText>
          )}

          <Label>Jenis Toko</Label>
          {editMode ? (
            <Form.Item>
              {getFieldDecorator('business_type', {
                rules: [{ required: true, message: 'Please input Jenis Toko' }],
                initialValue: toTitleCase(data?.business_type) || null,
              })(
                <Select placeholder="Jenis Toko">
                  {BUSINESS_TYPES.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          ) : (
            <TitleText>{data?.business_type}</TitleText>
          )}

          <Label>No Handphone</Label>
          {editMode ? (
            <Form.Item>
              {getFieldDecorator('phone', {
                rules: [{ required: true, message: 'Please input No Handphone' }],
                initialValue: data?.phone || null,
              })(<Input placeholder="No Handphone" disabled />)}
            </Form.Item>
          ) : (
            <TitleText>{data?.phone}</TitleText>
          )}

          {!editMode && (
            <>
              <Label>Email</Label>
              <TitleText>{data?.email}</TitleText>
            </>
          )}

          {merchantData && (
            <>
              <Label>External Registered Date</Label>
              <TitleText>{merchantData?.registered_at ? moment(merchantData?.registered_at).format(DATE_FORMAT) : ''}</TitleText>
            </>
          )}

          {data.initial_type === 'lead' && (
            <>
              <Label>Lead Submission Date</Label>
              <TitleText>{moment(data?.created_date_utc).format(DATE_FORMAT)}</TitleText>
            </>
          )}

          <Label>Submitted Date information</Label>
          <TitleText>{moment(data?.submitted_date).format(DATE_FORMAT)}</TitleText>

          {!isInReview && (
            <>
              <Label>Reviewed By</Label>
              <TitleText>{data?.reviewed_by}</TitleText>

              <Label>Reviewed Date information</Label>
              <TitleText>{moment(data?.reviewed_date).format(DATE_FORMAT)}</TitleText>
            </>
          )}
        </Col>

        <Col span={isMobile ? 24 : 12}>
          <Label>Alamat</Label>
          {editMode ? (
            <>
              <Form.Item>
                {getFieldDecorator('address', {
                  rules: [{ required: true, message: 'Please input address' }],
                  initialValue: data?.address || null,
                })(<Input placeholder="Alamat" />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('location_id', {
                  rules: [{ required: true, message: 'Please input location' }],
                  initialValue: data?.location_id || null,
                })(
                  <Select
                    placeholder="Location"
                    showSearch
                    filterOption={false}
                    onSearch={onSearchVillage}
                    onChange={onChangeLocation}
                    notFoundContent={isLoading ? <Spin size="small" /> : 'Data not Found'}
                  >
                    {!isLoading && villages.length > 0 ? (
                      villages.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))
                    ) : (
                      <Select.Option key={data?.location_id} value={data?.location_id}>
                        {data?.location}
                      </Select.Option>
                    )}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('location', {
                  rules: [],
                  initialValue: data?.location || null,
                })(<Input placeholder="Location" hidden />)}
              </Form.Item>
            </>
          ) : (
            <TitleText>{`${data?.address}, ${data?.location}`}</TitleText>
          )}

          <Row gutter={10} type="flex" justify="start">
            <Col span={4}>
              <Label>RT</Label>
              {editMode ? (
                <Form.Item>
                  {getFieldDecorator('neighbourhood_number', {
                    rules: [
                      { required: true, message: 'Please input RT' },
                      { max: 5, message: 'Max 5 characters' },
                    ],
                    initialValue: data?.neighbourhood_number || null,
                  })(<Input placeholder="RT" />)}
                </Form.Item>
              ) : (
                <TitleText>{data?.neighbourhood_number}</TitleText>
              )}
            </Col>
            <Col span={4}>
              <Label>RW</Label>
              {editMode ? (
                <Form.Item>
                  {getFieldDecorator('hamlet_number', {
                    rules: [
                      { required: true, message: 'Please input RW' },
                      { max: 5, message: 'Max 5 characters' },
                    ],
                    initialValue: data?.hamlet_number || null,
                  })(<Input placeholder="RW" />)}
                </Form.Item>
              ) : (
                <TitleText>{data?.hamlet_number}</TitleText>
              )}
            </Col>
            <Col span={4}>
              <Label>Kode Pos</Label>
              {editMode ? (
                <Form.Item>
                  {getFieldDecorator('zip_code', {
                    rules: [
                      { required: true, message: 'Please input Kode Pos' },
                      { min: 5, message: 'Kode Pos must be 5 characters.' },
                      { max: 5, message: 'Kode Pos must be 5 characters.' },
                    ],
                    initialValue: data?.zip_code || null,
                  })(<Input placeholder="Kode Pos" />)}
                </Form.Item>
              ) : (
                <TitleText>{data?.zip_code}</TitleText>
              )}
            </Col>
          </Row>

          <Label>Patokan</Label>
          {editMode ? (
            <Form.Item>
              {getFieldDecorator('note', {
                rules: [{ required: true, message: 'Please input Patokan' }],
                initialValue: data?.note || null,
              })(<Input placeholder="Patokan" />)}
            </Form.Item>
          ) : (
            <TitleText>{data?.note}</TitleText>
          )}

          {data.location && !editMode && (
            <>
              <Label>Lokasi</Label>
              <TitleText>{data.location}</TitleText>
            </>
          )}

          <GoogleMapDisplay width="500" height="300" latitude={data?.latitude} longitude={data?.longitude} />

          <Label>Data Tambahan</Label>
          <Row gutter={8} className="w-full max-w-2xl">
            {isEmpty(additionalData) ? (
              <span className="px-2">-</span>
            ) : (
              Object.entries(additionalData).map(([key, value]) => (
                <React.Fragment key={key}>
                  <Col span={10}>
                    <b>{key}</b>
                  </Col>
                  <Col span={14}>{value}</Col>
                </React.Fragment>
              ))
            )}
          </Row>
        </Col>
      </Row>

      <Row gutter={12} className="mt-10" style={{ minHeight: 350 }}>
        <Col span={isMobile ? 24 : 8}>
          <LabelWithBackground>Foto Toko</LabelWithBackground>
          {data?.business_image_key && (
            <ImageViewer
              switchable
              imageKey={data?.business_image_key || ''}
              src={`${trimSlashes(CONTENT_API_URL)}/${data?.business_image_key || ''}`}
            />
          )}
        </Col>
        <Col span={isMobile ? 24 : 8}>
          <LabelWithBackground>Foto Pemilik Toko</LabelWithBackground>
          {data?.owner_image_key && (
            <ImageViewer
              switchable
              imageKey={data?.owner_image_key || ''}
              src={`${trimSlashes(CONTENT_API_URL)}/${data?.owner_image_key || ''}`}
            />
          )}
        </Col>
        <Col span={isMobile ? 24 : 8}>
          <LabelWithBackground>Foto Dengan Pemilik Toko</LabelWithBackground>
          {data?.owner_business_image_key && (
            <ImageViewer
              switchable
              imageKey={data?.owner_business_image_key || ''}
              src={`${trimSlashes(CONTENT_API_URL)}/${data?.owner_business_image_key || ''}`}
            />
          )}
        </Col>
      </Row>

      <p>Acquired By: {data.acquired_by}</p>
    </Card>
  );
}

SectionDetail.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  editMode: PropTypes.bool,
  getFieldDecorator: PropTypes.func.isRequired,
  setFieldsValue: PropTypes.func.isRequired,
};

SectionDetail.defaultProps = {
  data: {},
  editMode: false,
};

export default SectionDetail;
