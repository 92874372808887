import React, { useState } from 'react';
import { Modal, Row, Col } from 'antd';
import { Button, AllText, ImageViewer } from 'components';
import PropTypes from 'prop-types';
import { CONTENT_API_URL } from 'config/url';
import { VISITATION_STATUS_OPTIONS } from 'config/visit-assignment';
import Tag from 'components/Tag/v2';
import moment from 'moment';

const { LabelWithBackground } = AllText;

const getLabel = (label) => <p className="text-cf-gray text-xl">{label}</p>;
const getGreyBox = (children) => <div className="bg-cf-lightGray-3 mt-2 p-4 rounded-xl font-semibold text-2xl">{children}</div>;

function ViewReportButton({ data }) {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const status = VISITATION_STATUS_OPTIONS.find((v) => v.key === data.visitation_status);

  return (
    <>
      <Button size="small" className="border-cf-gray text-cf-gray" onClick={() => setOpen(true)}>
        View Report
      </Button>

      <Modal width={600} destroyOnClose visible={open} onCancel={close} maskClosable={false} footer={null}>
        <div>
          <h2 className="mb-12 font-bold text-3xl">Report</h2>
          <Row gutter={16}>
            <Col span={6}>
              {getLabel('Visitation Date')}
              {getGreyBox(data.visitation_date ? moment(data.visitation_date).format('DD/MM/YYYY') : '-')}
            </Col>
            <Col span={12}>
              {getLabel('Visitor')}
              {getGreyBox(`${data.visitor_name}/${data.visitor_position}`)}
            </Col>
            <Col span={6}>
              {getLabel('Visitation Status')}
              <Tag className="mt-4" bgColor={status?.bgColor} textColor={status?.textColor}>
                {status.label}
              </Tag>
            </Col>
          </Row>

          <div className="mt-12">
            {getLabel('Report Category')}
            {getGreyBox('25/23/1700')}
          </div>
          <div className="mt-12">
            {getLabel("Supervisor's Notes")}
            {getGreyBox(data.assignment_note || '-')}
          </div>
          <div className="mt-12">
            {getLabel('Report Description')}
            {getGreyBox(data.visitation_note || '-')}
          </div>

          <div className="gap-8 grid grid-cols-1 md:grid-cols-2 mt-8">
            <div>
              <LabelWithBackground>Foto Toko</LabelWithBackground>
              <div style={{ zoom: 0.7 }}>
                <ImageViewer switchable imageKey={data.owner_image_key} src={`${CONTENT_API_URL}${data.owner_image_key}`} />
              </div>
            </div>
            <div>
              <LabelWithBackground>Foto Pemilik Toko</LabelWithBackground>
              <div style={{ zoom: 0.7 }}>
                <ImageViewer switchable imageKey={data.image_key} src={`${CONTENT_API_URL}${data.image_key}`} zoom={0.7} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

ViewReportButton.propTypes = {
  data: PropTypes.object,
};

ViewReportButton.defaultProps = {
  data: {},
};

export default ViewReportButton;
